import { Tabbar, TabbarItem, Swipe, SwipeItem, Button, Form, Field, Area, Popup, Col, Row,Loading,Overlay,Icon ,Checkbox,Search  } from 'vant';
import 'vant/lib/index.css';
import './rem'
import 'lib-flexible/flexible'
import { createApp } from "vue";
import { getShopId,CookieSetting  } from '@/utils/index'
// import Vue from 'vue'
import App from "./App.vue";
import router from "./router";
// Vue.prototype.getShopId = getShopId
// Vue.property.$cookieStore = CookieSetting;
const app = createApp(App)
app.use(Tabbar)
  .use(TabbarItem)
  .use(Swipe)
  .use(SwipeItem)
  .use(Button)
  .use(Form)
  .use(Field)
  .use(Area)
  .use(Popup)
  .use(Col)
  .use(Row)
  .use(Loading)
  .use(Overlay)
  .use(Icon)
  .use(Search)
  .use(Checkbox)

app.use(router).mount("#app");
