import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: '/',
    // name: 'home',
    component:  () => import( "../views/home/home.vue"),
  },
  {
    path: '/:id',
    // name: 'home',
    component:  () => import( "../views/home/home.vue"),
  },
  {
    path: '/:id/:sellName',
    // name: 'home',
    component:  () => import( "../views/home/home.vue"),
  },
  {
    path: "/home/:id",
    // name: "home",
    component:  () => import( "../views/home/home.vue"),
  },
  {
    path: '/goodsDetail/:id',
    name: 'goodsDetail',
    component: () => import("../views/home/detail.vue"),
  },
  {
    path: '/goodsDetail/:id/:sellId',
    // name: 'goodsDetail',
    component: () => import("../views/home/detail.vue"),
  },
  {
    path: "/online",
    name: "online",
    component:  () => import( "../views/online/index.vue"),
  },
  {
    path: "/order",
    name: "order",
    component: () => import( "../views/order/order.vue")
  },
  {
    path: "/agreeMent/:id/:goods_id/:is_sup_notice/:province",
    name: "agreeMent",
    component: () => import( "../views/home/agreement.vue")
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
