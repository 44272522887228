
/**
 * 表格时间格式化
 */
 import { computed, ref, reactive, toRefs } from "vue";
const getShopId = ref(5);
export function getCookie(name) {
  let str = sessionStorage.getItem(name);
  return str;
    // var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    // console.log(arr,111111111111);
    // console.log(reg,22222222222);
    // if (arr = document.cookie.match(reg))
    //   return (arr[2]);
    // else
    //   return null;
  }
  export function setCookie (c_name, value, expiredays) {
    sessionStorage.setItem(c_name,value);
    // var exdate = new Date();
    // exdate.setDate(exdate.getDate() + expiredays);
    // document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString()+';path=/');
  };
  export function delCookie (name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = getCookie(name);
    if (cval != null)
     document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
  };
export {
    getShopId,
}
